import methods from '@/enums/methods';
import Ramses from '../base/ramses';

class ShortcutService extends Ramses {
    async index() {
        return this.fetch({
            url: '/shortcut',
        });
    }

    async store(data) {
        return this.fetch({
            url: '/shortcut',
            data,
            method: methods.POST,
        });
    }
}

export default new ShortcutService();
