<template>
  <b-col
    md="6"
    lg="6"
    xl="3"
    class="position-relative p-0"
    @mouseover="show=true"
    @mouseleave="show=false"
  >
    <b-button
      v-if="removeButton && show"
      variant="transparent"
      rounded
      style="z-index: 999; right: 8px;"
      class="position-absolute p-2"
      @click="$emit('delete')"
    >
      <p>
        <FeatherIcon
          width="32px"
          height="32px"
          icon="XIcon"
        />
      </p>
    </b-button>
    <b-button
      v-if="href && redirect"
      class="w-100 "
      :to="{
        name: href
      }"
      :style="`cursor: ${redirect ? 'pointer' : 'auto'} !important`"
      variant="transparent"
    >

      <b-card
        style="height: 200px;"
        class="py-3"
      >
        <slot />
      </b-card>
    </b-button>

    <b-button
      v-else
      class="w-100"
      variant="transparent"
      :style="`cursor: ${!redirect && !href ? 'pointer' : 'auto'} !important`"
      @click="() => $emit('clicked')"
    >
      <b-card
        style="height: 200px;"
        class="py-3"
      >
        <slot />
      </b-card>
    </b-button>
  </b-col>
</template>

<script>
import {
    BCard, BButton, BCol,
} from 'bootstrap-vue';

export default {
    components: {
        BButton,
        BCard,
        BCol,
    },

    props: {
        href: {
            type: String,
            default: '',
        },

        redirect: {
            type: Boolean,
            default: () => false,
        },

        removeButton: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            show: false,
        };
    },
};
</script>
