<template>
  <b-row class="py-3">
    <shortcut-card
      v-for="(item, index) in shortcut"
      :key="index"
      :href="item.menu.route"
      remove-button
      :redirect="!item.new"
      @delete="deleteShortcut(index)"
    >
      <b-row class="d-flex flex-nowrap flex-column justify-content-between h-100 align-items-center">

        <h3 class="text-center">
          {{ item.menu.title }}
        </h3>
        <p class="mb-0">
          {{ item.description }}
        </p>
      </b-row>
    </shortcut-card>

    <ShortcutCard
      v-if="shortcut.length < 12"
      @clicked="$refs.modal.show();"
    >
      <FeatherIcon
        width="56px"
        height="56px"
        class="w-100  "
        icon="PlusIcon"
      />
    </ShortcutCard>

    <b-modal
      ref="modal"
      hide-footer
    >
      <shortcut-form
        :shortcuts="shortcut"
        @clicked="clicked"
      />
    </b-modal>

    <b-col
      v-if="updated"
      md="12"
    >
      <b-button
        variant="primary"
        class="mx-auto d-block my-1"
        @click="update"
      >
        Salvar
      </b-button>
    </b-col>

  </b-row>
</template>

<script>
import {
    BRow, BModal, BCol, BButton,
} from 'bootstrap-vue';

import ShortcutForm from './Components/Form.vue';

import ShortcutCard from './Components/Card.vue';

import ShortcutService from '@/service/shortcut';

export default {
    components: {
        BCol,
        BButton,
        ShortcutCard,
        BRow,
        BModal,
        ShortcutForm,
    },
    data() {
        return {
            shortcut: [],
            updated: false,
        };
    },

    async mounted() {
        this.show();
    },

    methods: {
        async show() {
            this.callLoading(true);
            const response = await ShortcutService.index();
            this.callLoading(false);
            this.shortcut = response.data.data;
        },
        clicked(a) {
            this.updated = true;
            this.shortcut.push({
                menu: {
                    id: a.id,
                    route: a.route,
                    title: a.title,
                },
                new: true,
                description: a.description,
            });

            this.$refs.modal.hide();
        },

        deleteShortcut(index) {
            this.updated = true;
            this.shortcut.splice(index, 1);
        },

        async update() {
            const response = await this.confirmAnAction('Deseja realmente salvar os seus atalhos?');

            if (!response) return;
            this.callLoading(true);
            const { status } = await ShortcutService.store({
                items: this.shortcut.map(item => ({
                    menu_item_id: item.menu.id,
                    description: item.description,
                })),
            });
            this.callLoading(false);

            if (status === 201) {
                this.modalSuccess('Atalhos alterados com sucesso');
                this.updated = false;
                await this.show();
                return;
            }

            this.modalError('Houve um erro ao atualizar os atalhos');
        },
    },
};
</script>
