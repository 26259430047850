<template>
  <div>
    <b-card>

      <b-card-text><h2>Bem vindo ao BackOffice do Grupo ViaInvest.</h2></b-card-text>
      <b-card-text><h4>O portal de suas tarefas diárias.</h4></b-card-text>
      <b-card-text>
        Neste portal você poderá executar e acompanhar algumas tarefas relacionadas a sua rotina diária.
        Confira as funções oferecidas para o seu departamento e bom trabalho!
      </b-card-text>
    </b-card>

    <Shortcut />

  </div>
</template>

<script>
import {
    BCard, BCardText,
} from 'bootstrap-vue';

import Shortcut from './pages/Shortcut/Shortcut.vue';

import ShortcutService from '@/service/shortcut';

export default {
    components: {
        BCard,
        BCardText,
        Shortcut,
    },
    data() {
        return {
            shortcut: [],
        };
    },

    async mounted() {
        const response = await ShortcutService.index();
        this.shortcut = response.data.data;
    },
};
</script>

<style>
</style>
