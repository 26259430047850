<template>
  <b-form>
    <b-form-group
      v-for="(item, index) in selected"
      :key="index"
      :label="item.options.title || 'Categoria'"
      label-for="title"
    >
      <b-form-select
        v-model="selected[index].value"
        :options="childrenToOptions(item.options)"
        @change="() => onChange(index)"
      />
    </b-form-group>

    <b-form-group
      label-for="description"
      label="Descrição"
    >
      <b-form-input
        v-model="description"
        placeholder="Descrição"
      />
    </b-form-group>

    <p v-if="invalid && isLast">
      <small class="text-danger">
        Esse atalho já existe com essa descrição
      </small>
    </p>

    <p>
      <small
        v-if="alreadyUsed && !invalid && isLast"
        class="text-warning"
      >
        Esse atalho já existe com outra descrição
      </small>
    </p>

    <b-button
      :disabled="selected.some(item => !item.value) || (invalid) || description.length >= 50"
      variant="primary"
      @click="$emit('clicked', {
        ...selected[selected.length - 1].value,
        description
      })"
    >
      Criar
    </b-button>

  </b-form>
</template>

<script>
import {
    BFormSelect, BForm, BFormGroup, BButton, BFormInput,
} from 'bootstrap-vue';

export default {
    components: {
        BButton,
        BFormSelect,
        BForm,
        BFormGroup,
        BFormInput,
    },
    props: {
        shortcuts: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            description: '',
            selected: [],
        };
    },

    computed: {
        invalid() {
            const founded = this.shortcuts.find(this.callbackShortcut);

            if (!founded) {
                return false;
            }

            if ((founded.description || '') === this.description) {
                return true;
            }

            return false;
        },

        alreadyUsed() {
            return this.shortcuts.some(this.callbackShortcut);
        },

        isLast() {
            return !!(this.selected[this.selected.length - 1]?.value?.route);
        },
    },

    mounted() {
        const menu = JSON.parse(JSON.stringify(this.$store.state.user.data.menu));
        menu.shift();
        this.selected.push({
            options: menu.filter(menuItem => menuItem),
            value: null,
        });
    },
    methods: {
        callbackShortcut(item) {
            const menuItemSelected = this.selected[this.selected.length - 1];
            return (item.menu?.id || item.value.menu_item_id) === (menuItemSelected.value?.menu?.id || menuItemSelected.value?.id);
        },
        childrenToOptions(item) {
            if (!item) return item;
            if (Array.isArray(item)) {
                const items = item.map(child => ({
                    value: child,
                    text: child?.title,
                }));

                items.unshift({
                    value: null,
                    text: 'Selecione uma opção',
                });

                return items;
            }

            if (!item.children) {
                return [];
            }

            const items = item.children.map(child => ({
                value: child,
                text: child.title,
            }));

            items.unshift({
                value: null,
                text: 'Selecione uma opção',
            });

            return items;
        },

        onChange(value) {
            this.selected.splice(value + 1, this.selected.length - 1);

            if (this.selected[value].value?.route) {
                return;
            }

            this.selected.push({
                options: this.selected[value].value,
                value: null,
            });
        },
    },
};
</script>
